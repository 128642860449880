@tailwind base;
@tailwind components;
@tailwind utilities;

.cursor {
    cursor:pointer;
}

.white-space {
    white-space: pre-wrap
}
